// Hooks
import usePreviewMode from "hooks/usePreviewMode";
// Assets
// import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
// import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
// import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-white.svg";
import Locale from "translations";
import { useHistory, useParams } from "react-router-dom";
import { useWebBuilderState } from 'context/webBuilder';
// import moment, { now } from "moment";

export default function TourCardThemeTen(props) {
	let {
		openEditModal,
		name,
		name_localized,
		price,
		image,
		currency,
		product_uuid,
		initialName,
		locationName,
		city,
		index,
		// departure_date_at,
		// return_date_at,
		// stars,
	} = props;
	const { webBuilder } = Locale;
	const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();
	const history = useHistory();
	const params = useParams();
	const tourUrl = `/${params.bundle}/web-builder-v2/preview/tour/${product_uuid}`;
	// let rate = +stars && +stars > 5 ? 5 : +stars;

	return (
		<div
			className={`tour-card ${!product_uuid ? "empty-service" : ""}`}
			onClick={() => isPreview && history.push(tourUrl)}
			style={{
				background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(var(--web-page-primary-color), 0.6) 100%), url(${
					image || ""
				})`,
			}}
		>
			{!isPreview && !image ? (
				<button
					className="btn add-service-btn"
					onClick={() => openEditModal({ ...props, index })}
				>
					<AddIcon />
				</button>
			) : null}

			<div className="card-content">
				{/* star */}
				{/* <div className="rate">
					{[...Array(5)].map((i, index) =>
						index < rate ? (
							<GoldenStarIcon key={index} />
						) : (
							<StarIcon key={index} />
						)
					)}
				</div> */}

				<h4 className="title">{name_localized?.[selectedLanguage] || name || initialName}</h4>
				{/* package name and location */}
				<div className="card-details d-flex gap-10">
					<p className="location">
						<LocationIcon />
						<span className="mx-2">
							{locationName?.[selectedLanguage] || locationName?.en || locationName}
							{city && <span>, {city}</span>}
						</span>
					</p>
					{/* <p>
						<CalendarIcon />
						<span className="mx-2">
							{`${moment(departure_date_at || now()).format(
								"DD MMM YYYY"
							)} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
						</span>
					</p> */}
				</div>

				<p className="price">
					<span>{webBuilder.from}</span>
					<span className="amount">{price}</span>{" "}
					<span className="currency">{currency}</span>
				</p>
			</div>
		</div>
	);
}
