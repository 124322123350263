import { useWebBuilderState } from 'context/webBuilder';
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { transalteText } from 'services/webBuilder';

import { ReactComponent as MagicPenIcon } from "assets/images/webBuilder/magicpen.svg";
import Locale from 'translations';


export async function handleWBAutoTranslate({ targetLanguage, text, cb }) {
	const data = {
		to: targetLanguage,
		text: text
	}
	const res = await transalteText(data);
	if (res?.status === 200) {
		cb && cb(res)
	}
}

function EditableParagraph({
	contentEditable,
	className,
	text,
	onInput,
	preventTranslate,
	...restProps
}) {
	const { selectedLanguage } = useWebBuilderState();
	const { webBuilder } = Locale;
	const [showInput, setShowInput] = useState(false);
	const location = useLocation();
	const preview = !location.pathname.includes('edit');
	const openEdit = () => {
		setShowInput(contentEditable);
	};
	const cancleEdit = (e) => {
		if (e && !e.relatedTarget) {
			setShowInput(false)
		}
	};

	return showInput && !preview ? (
		<div className="w-100">
			{!preventTranslate && text && text.length > 0 &&
				<button className="magic-translate-btn"
					onClick={() =>
						handleWBAutoTranslate({
							targetLanguage: selectedLanguage?.toUpperCase(),
							text,
							cb: (res) => {
								setShowInput(false);
								onInput(res?.data?.result);
							}
						})
					}
				>
					<MagicPenIcon />
					<span>Magic translate</span>
				</button>
			}
			<textarea
				style={{ background: "none", resize: "vertical", border: "2px solid #D4A655" }}
				autoFocus
				className={`${className} w-100 rounded text-center`}
				onChange={(e) => {
					const value = e.target.value;
					onInput(value);
				}}
				value={text}
				onBlur={cancleEdit}
			/>

		</div>
	) : (
		<p onClick={openEdit} className={className}>
			{preview ? (text || "") : (text || webBuilder.enterYourText)}
		</p>
	);
}

export default EditableParagraph;
