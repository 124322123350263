import { useWebBuilderState } from 'context/webBuilder';
import { useState } from 'react';
import FacebookIcon from 'modules/WebBuilder-V2/shared/icons/Facebook';
import LinkedinIcon from 'modules/WebBuilder-V2/shared/icons/Linkedin';
import TwitterIcon from 'modules/WebBuilder-V2/shared/icons/Twitter';
import YoutubeIcon from 'modules/WebBuilder-V2/shared/icons/Youtube';
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting.svg";
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import SocialMediaModal from './SocialMediaModal';
import useWBFooter from './useWBFooter';
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram-colorful.svg'
import { ReactComponent as TiktokIcon } from 'assets/images/webBuilder/tiktok-colorful.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/webBuilder/snapchat-colorful.svg'
import { isObject } from "lodash"
import Locale from 'translations';

const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function FooterThemeSeven({ focusContainer, handleFocus }) {
  const {webBuilder} = Locale;
  const { footer: footerDetails, hero, selectedLanguage, ourServices } = useWebBuilderState();
  const type = "footer";
  const [socialMediaModal, setSocialMediaModal] = useState(false);
  const {updateFooterText} = useWBFooter();

  const socialMediaIcons = {
		facebook: <FacebookIcon />,
		twitter: <TwitterIcon />,
		youtube: <YoutubeIcon />,
		instagram: <InstagramIcon />,
		linkedin: <LinkedinIcon />,
		snapchat: <SnapchatIcon />,
		tiktok: <TiktokIcon />
	}
  const services = ourServices?.services?.filter(service => service?.visible);
  const header = hero?.header;
  const firstSlide = hero?.mainContent?.sliders[0];
  const logoImage = isObject(header?.logo) ?
    <img src={`${header?.logo[selectedLanguage]?.includes("http") ? header?.logo[selectedLanguage] : URI + "/" + header?.logo[selectedLanguage]}`} alt="Logo" />
    :
    <img src={`${header?.logo[selectedLanguage]}`} alt="Logo" />;
  function toggleSocialMediaModal() {
    setSocialMediaModal(!socialMediaModal);
  }


  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const containerBoxActions = [
    <span onClick={toggleSocialMediaModal} className="pointer">
      <SettingIcon />
    </span>,
  ];

  return (
    <div className="theme_footer_7">
      <img
        className="footer_bg"
        src={firstSlide?.src || firstSlide || "https://back-sbs.staging.safa.asia/api/v1/files/fetch/b1df907a-9962-43c4-8708-1febec5895f4"}
        alt="Safa Business Solutions"
      />
      <div className="footer_content">
        <div className="logo_container">
          {logoImage}
        </div>
        <div className="footer_text">
          <EditableParagraph
            className="body text-primary-web-builder"
            contentEditable={true}
            onInput={(value) => {
              updateFooterText({ name: "footerText", value, language: selectedLanguage });
            }}
            text={footerDetails?.footerText[selectedLanguage]}
            preventTranslate={false}
          />
        </div>
        <div className="info_container" onClick={() => handleFocus(type)}>
          <div className="social_media">
            <p className="text">
              {webBuilder.followUsOn} :
            </p>
            <div>
              <EditBox
                editMode={focusContainer === type}
                defaultIcon={containerBoxActions}
              >
                <ul className="links">
                  {footerDetails?.links?.map((link) => {
                    if (link?.visible) {
                      return (
                        <div key={link?.id}>
                          {/* <a href={link?.url} target="_blank" rel="noreferrer"> */}
                          {socialMediaIcons[link?.id]}
                          {/* </a> */}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
              </EditBox>
            </div>
          </div>
          <div className="contact_phone">
            <p className="text">{webBuilder.contactUs} : </p>
            <p className="phone">
              {/* <a href="tel:012345678"> */}
                {header?.phoneNumber}
              {/* </a> */}
            </p>
          </div>
        </div>
        <ul className="footer_links">
          {services && services.length > 0 && services?.map(service => {
            return (
              <li className="pointer"
                onClick={() => scrollToSection(service?.id)}
              >
                {/* <a href="/">Hotels</a> */}
                < span > {service?.header[selectedLanguage]}</span>
              </li>
            )
          })}
        </ul>
        {/* <div className="copyright_text">
          <p>
            &copy; 2024 Company Name - All Rights Reserved.
          </p>
        </div> */}
      </div>
      {socialMediaModal && footerDetails?.links ? (
        <SocialMediaModal
          isOpen={socialMediaModal}
          toggle={toggleSocialMediaModal}
          header={"Social links"}
          socialMediaLinks={footerDetails?.links}
        />
      ) : null}
    </div>
  )
}