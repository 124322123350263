import { useWebBuilderState } from "context/webBuilder";
import { useHistory, useParams } from "react-router-dom";
import useWBHeader from './useWBHeader';
import WebBuilderNav from './MainNav';


export default function HeaderThemeFour({ toggleLogoChangeModal, contactPhoneComponent, clickContactButton }) {
  // *** hooks
  const { selectedLanguage } = useWebBuilderState();

  const { bundle } = useParams();
  const history = useHistory();

  const {
    headerData,
    isPreview,
    logoImage
  } = useWBHeader();



  return (
    <header className="wb-header">
      {/* logo */}
      <div className="logo"
        onClick={() => {
          // check if we inside preview 
          if (isPreview) {
            history.push(`/${bundle}/web-builder-v2/preview`);
          } else {
            toggleLogoChangeModal();
          }
        }}
      >
        {logoImage}
      </div>


      {/* nav */}
      <nav className="header-nav">
        <WebBuilderNav />
        {/* contact button */}
        <div className="d-flex-language-contact d-flex align-items-center gap-10">
          <button
            className="contact-button mr-2"
            onClick={() => {
              // check if we inside view package page when click scroll back to preview page the scroll using useeffect
              if (isPreview) {
                history.push({ pathname: `/${bundle}/web-builder-v2/preview`, state: headerData?.contactUs?.id });
              } else {
                clickContactButton()
              }
            }}
          >
            {typeof headerData?.contactUs?.name === "string" ? headerData?.contactUs?.name : (headerData?.contactUs?.name?.[selectedLanguage] || "Let's Talk")}
          </button>

          {/* phone */}
          {contactPhoneComponent}

        </div>
      </nav>


    </header>
  );
}
