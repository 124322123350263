import React from "react";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";

import Locale from "translations";
import usePreviewMode from "hooks/usePreviewMode";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";
import Carousel from "react-multi-carousel";
import { useWebBuilderState } from 'context/webBuilder';

const hotelBlocksType = "hotelBlocksType";

export default function HotelTheme4({
	items,
	sortHotelsItems,
	focusContainer,
	editItem,
	itemsBoxActions,
	type,
	openEditModal,
}) {
	const { webBuilder } = Locale;
	const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 567 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 567, min: 0 },
			items: 1,
		},
	};

	const ButtonGroup = ({ next, previous }) => {
		return (
			<div className="wb-button-group-new">
				<CustomLeftArrow onClick={() => previous()} />
				<CustomRightArrow onClick={() => next()} />
			</div>
		);
	};

	return (
		<Carousel
			responsive={responsive}
			itemClass={"service-slide"}
			className="hotel-container"
			slidesToSlide={1}
			arrows={false}
			keyBoardControl={true}
			customButtonGroup={<ButtonGroup />}
			renderButtonGroupOutside={true}
			removeArrowOnDeviceType={["tablet", "mobile"]}
			renderArrowsWhenDisabled={true}
			infinite={true}
		>
			{items.map((hotel, index) => (
				<DragAndDropContainer
					accept={hotelBlocksType}
					type={hotelBlocksType}
					id={hotel?.id}
					index={index}
					action={SORT_ITEMS_IN_CONTAINER_ACTION}
					moveItemInContainer={(dragIndex, hoverIndex) =>
						sortHotelsItems({ dragIndex, hoverIndex })
					}
					key={hotel?.id || index}
				>
					<EditBox
						editMode={focusContainer === type}
						actions={[...editItem(hotel, index), ...itemsBoxActions(hotel?.id)]}
					>
						<div
							key={index}
							className={`card-holder ${!hotel?.product_uuid ? "empty-service" : ""
								}`}
						>
							<div className={`service-img`}>
								{!hotel?.product_uuid && !isPreview ? (
									<button
										className="btn add-service-btn"
										onClick={() => openEditModal({ ...hotel, index: index })}
									>
										<AddIcon />
									</button>
								) : null}

								{hotel?.image ? (
									<img src={hotel?.image} alt="our package" />
								) : (
									<img
										src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
										alt="Safa Visa"
									/>
								)}
							</div>

							<div className="card-caption-holder">
								<h3 className="card-title mb-0">
									{hotel?.name_localized?.[selectedLanguage] || hotel?.name}
								</h3>

								<div className="rate mb-2">
									{[...Array(5)].map((i, index) =>
										index < hotel?.stars ? (
											<GoldenStarIcon key={index} />
										) : (
											<StarIcon key={index} />
										)
									)}
								</div>

								{/* Hotel Location */}
								<div className="pb-2">
									<LocationIcon className="location-icon" />
									<span className="location">
										{hotel?.locationName?.[selectedLanguage] || hotel?.locationName?.en || hotel?.locationName}
										{hotel?.city ? ` - ${hotel?.city}` : null}
									</span>
								</div>

								{/* Card Action and Pricing */}
								<div className="card-action-holder">
									<div className="card-price">
										<p className="label">{webBuilder?.startFrom}</p>

										<div className="d-flex align-items-center">
											<p className="price">{hotel?.price}</p>
											<p className="ml-1">
												{hotel?.currency} /<span>{webBuilder?.night}</span>
											</p>
										</div>
									</div>

									<button className="btn book-btn">
										{webBuilder?.bookNow}
									</button>
								</div>
							</div>
						</div>
					</EditBox>
				</DragAndDropContainer>
			))}
		</Carousel>
	);
}
