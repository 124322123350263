import Locale from "translations";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import usePreviewMode from "hooks/usePreviewMode";
import { useParams, useHistory } from "react-router-dom";
import moment, { now } from "moment";
import { useWebBuilderState } from 'context/webBuilder';

export default function PackageCardThemeFour(props) {
	const {
		// onClick,
		image,
		name,
		name_localized,
		departure_date_at,
		return_date_at,
		stars,
		product_uuid,
		hotelName,
		price,
		// locationName,
		openEditModal,
		initialName,
		currency,
	} = props;
	const { webBuilder } = Locale;
	const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();
	const history = useHistory();
	const params = useParams();
	const packageName = name_localized?.[selectedLanguage] ? name_localized?.[selectedLanguage] : (name || initialName);
	const packageUrl = `/${params.bundle}/web-builder-v2/preview/package/${product_uuid}`;

	return (
		<div className={`package-card-holder ${!product_uuid ? "empty-service" : ""}`}
			onClick={() => isPreview && history.push(packageUrl)}
			>
			<div className={`package-img`}>
				{!isPreview && !image ? (
					<button
						className="btn add-service-btn"
						onClick={() => openEditModal(props)}
					>
						<AddIcon />
					</button>
				) : null}

				{image ? (
					<img src={image} alt="our package" />
				) : (
					<img
						src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
						alt="Safa Visa"
					/>
				)}
			</div>

			<div className="card-caption-holder">
				<div className="rate mb-2">
					{[...Array(5)].map((i, index) =>
						index < stars ? (
							<GoldenStarIcon key={index} />
						) : (
							<StarIcon key={index} />
						)
					)}
				</div>

				<h3 className="card-title">{packageName}</h3>

				<div className="d-flex align-items-center mb-2">
					<CalendarIcon className="me-1" />
					<span>
						{`${moment(departure_date_at || now()).format(
							"DD MMM YYYY"
						)} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
					</span>
				</div>
				<div className="d-flex align-items-center mb-2">
					<Hotelcon className="me-1" />
					{hotelName ? hotelName : " - "}
				</div>

				<div className="d-flex justify-content-end">
					<div className="price">
						<span className="label">{webBuilder?.from}</span>
						<p>
							{price} <span>{currency}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
