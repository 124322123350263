// Translate
import Locale from "translations";
// Custom Hooks
import usePreviewMode from "hooks/usePreviewMode";
// Assets
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { useHistory, useParams } from "react-router-dom";
import { useWebBuilderState } from 'context/webBuilder';
// ------------------------------------------------------------------------------------------

export default function HotelCardThemeTen(props) {
  let {
    openEditModal,
    name,
		name_localized,
    price,
    image,
    currency,
    stars,
    product_uuid,
    initialName,
    locationName,
    // city,
    // index
  } = props;
  const { webBuilder } = Locale;
  const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();

  const history = useHistory();
  const params = useParams();
  const hotelUrl = `/${params.bundle}/web-builder-v2/preview/hotel/${product_uuid}`;
  // let rate = +stars && +stars > 5 ? 5 : +stars;


  return (
		<div className={`hotel-card ${!product_uuid ? "empty-service" : ""}`}
      onClick={() => isPreview && history.push(hotelUrl)}
    >
			<div className={`card-img`}>
				{!isPreview && !image ? (
					<button
						className="btn add-service-btn"
						onClick={() => openEditModal(props)}
					>
						<AddIcon />
					</button>
				) : null}

				{image ? (
					<img src={image} alt="our package" />
				) : (
					<img
						width={"100%"}
						src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
						alt="Safa Visa"
					/>
				)}
			</div>
			{/* card-caption */}
			<div className="card-content">
				<h2 className="title">{name_localized?.[selectedLanguage] || name || initialName}</h2>
				{/* star */}
				<div className="rate">
					{[...Array(5)].map((i, index) =>
						index < stars ? (
							<GoldenStarIcon key={index} />
						) : (
							<StarIcon key={index} />
						)
					)}
				</div>
				<p className="location">
					<LocationIcon />
					<span className="mx-2">{locationName?.[selectedLanguage] || locationName?.en || locationName}</span>
				</p>

				{/* price-info */}
				<div className="price">
					<span>{webBuilder.from}</span>
					<p className="amount"> {price}</p>
					<span className="currency">{currency}</span>
				</div>
			</div>
    </div>
  )
}