import React from 'react';
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import Locale from 'translations';
import { useWebBuilderState } from 'context/webBuilder';

export default function ServiceCardWithButton(props) {
  let { openEditModal, name, name_localized, price, image, currency, stars, isNew, cardType, product_uuid, initialName, locationName, city } = props;
  const { selectedLanguage } = useWebBuilderState();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const localeName = name_localized?.[selectedLanguage] || name;
  const trimName = localeName ? localeName?.length > 22 ? `${localeName?.substr(0, 22)}...` : localeName : initialName
  const hotelRating = +stars > 5 ? 5 : +stars;
  const priceFormat = price ? new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(price) : 0;
  const preview = !location.pathname.includes("edit");
  const {marketPlace} = Locale;

  return (
    <article className={`${cardType}-carousel-card service-card-with-btns`} key={product_uuid}>
      <div className="card-details">
        <div className={`card-img ${isNew ? " empty-service" : ""}`}>
          {image ?
            <img src={image} alt={trimName} />
            :
            isNew && !preview ?
              <button className="btn add-service-btn" onClick={() => openEditModal(props)}>
                <AddIcon />
              </button>
              :
              null
          }
        </div>
        <div className="card-content">
          <h4 className="service-name" title={name}>{trimName}</h4>
          {cardType === "hotels" &&
            <div className="rate mb-2">
              {[...Array(5)].map((i, index) =>
                index < hotelRating ? <GoldenStarIcon key={index} /> : <StarIcon key={index} />
              )}
            </div>
          }
          {locationName?.[selectedLanguage] || locationName?.en || locationName},
          {
            (locationName || city) && (
              <div className="location mt-2">
              <LocationIcon />
                <span>{locationName?.[selectedLanguage] || locationName?.en || locationName} / {city}</span>
            </div>
            )
          }
        </div>
        <div className="card-footer">
          <div>
            <span className="font-grey-light">{marketPlace?.messages?.startFrom}</span>
            <div className="d-flex align-items-center">
              <p className="amount me-1"> {priceFormat} </p>
              <div>
                <span className="currency">{currency ? currency :  ""}</span>
                <span className="font-grey-light">/{marketPlace?.messages?.night}</span>
              </div>
            </div>
          </div>
          <button className="btn web-builder-filled-btn"
            onClick={() => {
              if (product_uuid) {
                if (preview) {
                  if (params.name) {
                    history.push(`/web-v2/${params.name}/${cardType}/${product_uuid}`);
                  } else {
                    history.push(`/web-builder-v2/preview/${cardType}/${product_uuid}`);
                  }
                }
              }
            }}
          >
            {Locale?.webBuilder?.bookNow}
          </button>
        </div>
      </div>
    </article>
  )
}
