import { useWebBuilderState } from 'context/webBuilder';
import { formatPrice } from 'helpers/utils';
import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';

const arrowIcon = <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.5195 5.96L12.9995 3.47998L10.5195 1" stroke="#1E85FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M1 3.48047H13" stroke="#1E85FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>;

export default function FlightServiceCard(props) {
  const { product_uuid, name, name_localized, price, flightItems } = props;
  const { selectedLanguage } = useWebBuilderState();
  const location = useLocation();
  const params = useParams();

  let flightDestinations = Array.isArray(flightItems) ? flightItems : [];
  const preview = !location.pathname.includes("edit");

  function getFlightLink() {
    if (product_uuid && preview) {
      return (`/web-builder-v2/preview/flights/${product_uuid}`);
    } else if (product_uuid && !preview) {
      return (`/web-v2/${params.name}/flights/${product_uuid}`);
    }
  }

  return (
    <article className="flight-service-card">
      <div className="header">
        <h3>{name_localized?.[selectedLanguage] || name}</h3>
        <span className="destination-count"><b>{flightDestinations?.length}</b> Destination</span>
      </div>

      <div className="content d-flex align-items-center justify-content-between">
        {/* destinations list */}
        <div className="destinations-list">
          <DestinationPort
            fromPortCode={flightDestinations[0]?.fromPort?.code}
            fromCountry={flightDestinations[0]?.fromCountry?.name}
            toPortCode={flightDestinations[0]?.toPort?.code}
            toCountry={flightDestinations[0]?.toCountry?.name}
          />

          {/* show number of destionations after first destionation */}
          {flightDestinations.length > 1 &&
            <div className="addition-destinations">
              <span className="addition-destinations-count mx-3">
                +{flightDestinations.length - 1}
              </span>
              <div className="addition-destinations-list">
                {flightDestinations.length > 1 && flightDestinations.slice(1).map(destination => {
                  return (
                    <DestinationPort
                      fromPortCode={destination?.fromPort?.code}
                      fromCountry={destination?.fromCountry?.name}
                      toPortCode={destination?.toPort?.code}
                      toCountry={destination?.toCountry?.name}
                    />
                  )
                })}
              </div>
            </div>
          }

        </div>

        <div className="d-flex align-items-center gap-10">

          {/* flight price */}
          <p className="price">
            <span>From</span>
            <br />
            <span className="amount">{formatPrice(+price)}</span>
          </p>
          {/* button */}
          {
            preview ? (
              <Link to={getFlightLink()} className="cta-btn">
                View Deals
              </Link>
            ) : (
              <span className="cta-btn disabled-btn">
                View Deals
              </span>
            )
          }
        </div>
      </div>
    </article>
  )
}


function DestinationPort({ fromPortCode, fromCountry, toPortCode, toCountry }) {
  return (
    <div className="destination-port">
      <p className="font-weight-bold">
        <span>({fromPortCode})</span>
        <br />
        <span className="port-country">{fromCountry}</span>
      </p>

      {arrowIcon}

      <p className="font-weight-bold">
        <span>({toPortCode})</span>
        <br />
        <span className="port-country">{toCountry}</span>
      </p>
    </div>
  )
}
